import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"  viewBox="0 0 225.000000 225.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)">
<path d="M985 1840 c-179 -36 -328 -157 -397 -321 -18 -44 -22 -74 -22 -164
-1 -94 3 -119 24 -170 l24 -60 7 67 c10 94 34 162 86 240 74 113 190 190 330
222 94 21 143 20 239 -4 94 -24 170 -68 231 -133 42 -45 45 -47 39 -20 -17 69
-68 156 -123 211 -112 111 -284 162 -438 132z"/>
<path d="M1003 1423 c-10 -4 -13 -49 -13 -180 l0 -175 114 4 c125 3 164 19
177 69 8 33 -7 81 -32 99 l-20 16 20 19 c52 46 7 143 -68 147 -97 4 -167 4
-178 1z m195 -59 c24 -17 29 -56 9 -72 -7 -6 -46 -12 -87 -13 l-75 -2 -3 51
-3 52 68 0 c46 0 76 -5 91 -16z m10 -150 c28 -20 30 -69 3 -84 -11 -5 -54 -10
-95 -10 l-76 0 0 55 0 55 73 0 c49 0 80 -5 95 -16z"/>
<path d="M1400 1250 l0 -180 25 0 c25 0 25 1 25 80 0 44 2 80 4 80 2 0 39 -36
82 -80 68 -70 82 -80 113 -80 l36 0 -93 93 -92 92 70 70 c92 91 98 101 59 101
-26 0 -44 -14 -105 -80 l-74 -81 0 83 c0 81 0 82 -25 82 l-25 0 0 -180z"/>
<path d="M1836 1423 c-3 -4 -6 -84 -6 -180 l0 -173 25 0 25 0 0 178 c0 168 -1
178 -19 179 -10 1 -22 -1 -25 -4z"/>
<path d="M1331 926 c-43 -172 -188 -314 -367 -360 -124 -32 -265 -17 -383 40
-67 33 -167 131 -198 194 l-23 45 0 -40 c0 -153 106 -316 253 -389 89 -43 140
-55 237 -56 150 0 270 48 373 151 93 93 129 171 134 294 3 50 1 108 -4 130
l-9 40 -13 -49z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
